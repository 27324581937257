import React, {useEffect, useState} from "react";
import {graphql} from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import {withPrismicPreview} from 'gatsby-plugin-prismic-previews'
import SEO from "../components/seo";
import SoundControl from "../components/sound";
import {serialize} from "../utils/htmlSerializers";
import CCLogoWhite from "../images/Claritycoat AF new.svg";
import {ColorThemeContextProvider} from "../contexts/colorThemeContext";
import Layout from "../components/full-page/layout";

const PrismicBlogPost = ({ location, data, pageContext: { lang } }) => {
  // Control videos style
  const [videoWidth, setVideoWidth] = useState("100%");
  const [videoHeight, setVideoHeight] = useState("auto");

  const onWindowResize = () => {
    setVideoWidth(
      typeof  document !== 'undefined' && document.documentElement.clientWidth > 1280
        ? "100%"
        : "auto"
    );
    setVideoHeight(
      typeof  document !== 'undefined' && document.documentElement.clientWidth > 1280
        ? "auto"
        : "100%"
    );
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", onWindowResize);
    }

    onWindowResize();
  });

  /**
   * SoundControl
   */
  const mutedDefault = true;
  const [ isMuted, setIsMuted ] = useState(mutedDefault);

  const { prismicBlogPost: { data: { title , author , hero_image: heroImage, post_content1: postContent } } } = data;

  return (
    <>
      <SEO title={title.text} />
      <ColorThemeContextProvider>
        <Layout
          disappearHeader={true}
          initColorTheme={'dark'}
          logo={ CCLogoWhite }
          topNav={data.prismicHome?.data.menu_links}
          footerNav={data.prismicHome?.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
          currentLanguage={lang}
        >
          <div className="owners-area-home-hero-wrapper brand-content--mono1">
            <main id="owners-area-home-hero" className="owners-area-home-hero">
              <div data-reactroot="" className="owners-area-home-hero__frame">
                <div className="owners-area-home-hero__image"><video playsInline className="owners-area-home-hero__video" preload="auto" autoPlay muted={isMuted} loop
                                                                     poster={heroImage.url ? heroImage.url : "https://storage.googleapis.com/claritycoatvideos/Images/poster%20image.jpg"}
                                                                     style={{"width": videoWidth, "height": videoHeight}}>
                  {!heroImage.url && <source src="https://player.vimeo.com/external/519801149.hd.mp4?s=1eb2cb225cceda8a418d45727db9975808fc48fd&profile_id=174" type="video/mp4" />}
                  <img src={heroImage.url ? heroImage.url : "https://storage.googleapis.com/claritycoatvideos/Images/poster%20image.jpg"} alt={heroImage.alt} />

                </video></div>

                <button className="scroll-prompt">
                  Scroll for more
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="32" viewBox="0 0 18 29">
                    <path d="M0 2,0 -2 Z" id="upAndDown"/>
                    <g stroke="#FFF">
                      <rect fill="none" x="0.5" y="0.5" width="16.4" height="28" rx="8.2"/>
                      <path d="M8.6 5.2L8.6 13.6" strokeDasharray="13.6" strokeLinejoin="round">
                        <animate attributeName="stroke-dashoffset" from="13.6" to="-13.6" dur="1s"
                                 repeatCount="indefinite"/>
                      </path>
                      <animateMotion dur="3s" repeatCount="indefinite">
                        <mpath href="#upAndDown"/>
                      </animateMotion>
                    </g>
                  </svg>
                </button>

                <div className="car-reg-lookup">
                  <div className="car-reg-lookup__no-reg-header">
                    <div className="car-reg-lookup__text-header">

                    </div>
                  </div>
                  <div className="reg-form">
                    <h1 className="car-reg-lookup__heading">{title.text}</h1>
                    <h3 className="car-reg-lookup__tagline">{author.text}</h3>
                    {!heroImage.url && <SoundControl isMuted={isMuted} onClick={() => setIsMuted(!isMuted)} className={"gallery-page-control"} />}
                  </div>
                </div>
              </div>
            </main>
          </div>

          <section className="sub-content-block sub-content-block--blog">
            <div className="sub-content-block__item sub-content-block__item--text">
              <div>
                { postContent.map((content, idx) => (
                  <div key={`content-${ idx }`}>
                    <h2 style={{marginTop: '50px'}}><b>{content['section_title']['text']}</b></h2>

                    <RichText
                      render={content['section_content']['richText']}
                      linkResolver={linkResolver}
                      htmlSerializer={serialize}
                    />

                  </div>
                )) }
              </div>
            </div>
          </section>
        </Layout>
      </ColorThemeContextProvider>
    </>
  )
}

export const query = graphql`
  query BlogPostQuery($id: String, $lang: String) {
    prismicBlogPost(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
            text
        }
          
        author {
            text
        }
         
        post_content1 {
            section_title {
                text
            }
            
            section_content {
                richText
            }
        }
        
        hero_image {
            url
            alt
        }
      }
    }
    
    allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
    
    prismicHome(lang: {eq: $lang}) {
        data {
          menu_links {
              menu_link {
                  url
                  type
              }
              name {
                text
              }
          }
          footer_links {
            footer_link {
             url
             type
            }
            name {
              text
            }
          }
        }
     }
  }
`


export default withPrismicPreview(PrismicBlogPost);