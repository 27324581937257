import PropTypes from 'prop-types';
import styled from 'styled-components';
import SoundOnIcon from '../images/sound_on.svg';
import SoundOffIcon from '../images/sound_off.svg';

const SoundControl = styled.div`
  width: 30px;
  height: 30px;
  background: url(${ props => props.isMuted ? SoundOffIcon : SoundOnIcon }) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  opacity: 1.0;
  right: 5px;
  bottom: 80px;
  
  &.gallery-page-control {
    top: 50% !important;
    margin-top: -15px;
  }
  
  @media screen and (max-width: 768px) {
    bottom: 90px;
  } 
`;

SoundControl.propTypes = {
    isMuted: PropTypes.bool.isRequired
};

export default SoundControl;